<template>
    <div class="text-center">
        <div class="skeleton-container flex-wrap">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <div class="skeleton-item mx-1 my-2"></div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <div class="skeleton-item mx-1 my-2"></div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <div class="skeleton-item mx-1 my-2"></div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <div class="skeleton-item mx-1 my-2"></div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <div class="skeleton-item mx-1 my-2"></div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <div class="skeleton-item mx-1 my-2"></div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <div class="skeleton-item mx-1 my-2"></div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <div class="skeleton-item mx-1 my-2"></div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <div class="skeleton-item mx-1 my-2"></div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <div class="skeleton-item mx-1 my-2"></div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <div class="skeleton-item mx-1 my-2"></div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <div class="skeleton-item mx-1 my-2"></div>
          </div>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style>
  .skeleton-container{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .skeleton-item{
    height: 210px;
    animation: pulse 5s infinite;
  }
  @keyframes pulse {
  0% {
    background-color: rgb(192, 192, 192);
  }
  50% {
    background-color: #e0e0e0;
  }
    75% {
    background-color: #ffffff;
  }
}
</style>