<template>
    <div class="text-center">
        <div class="skeleton-container">
          <div class="skeleton-item">

          </div>
          <div class="skeleton-item">

          </div>
          <div class="skeleton-item">

          </div>
          <div class="skeleton-item">

          </div>
          <div class="skeleton-item">

          </div>
          <div class="skeleton-item">

          </div>
          <div class="skeleton-item">

          </div>
          <div class="skeleton-item">

          </div>
          <div class="skeleton-item">

          </div>
          <div class="skeleton-item">

          </div>
        </div>
    </div>
</template>
<script>
export default {}
</script>
<style lang="scss" scoped>
  .skeleton-container{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 25px 20px;
  }
  .skeleton-item{
    min-width: 150px;
    flex: 1;
    height: 218px;
    animation: pulse 5s infinite;
  }
  @keyframes pulse {
  0% {
    background-color: rgb(192, 192, 192);
  }
  50% {
    background-color: #e0e0e0;
  }
    75% {
    background-color: #ffffff;
  }
}
</style>