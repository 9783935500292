<template>
    <div>
      <template v-if="component=='catalog'">
        <Catalog/>
      </template>
      <template v-else>
        <Default/>
      </template>
    </div>
</template>
<script>
import Default from './skeleton-components/default.vue'
import Catalog from './skeleton-components/catalog.vue'
export default {
  props:{
    component: String
  },
  components:{
    Default, Catalog
  }
}
</script>
<style lang="scss" scoped>
  
</style>