
<div class="text-center">
    <div class="skeleton-container">
      <div class="skeleton-item">

      </div>
      <div class="skeleton-item">

      </div>
      <div class="skeleton-item">

      </div>
      <div class="skeleton-item">

      </div>
      <div class="skeleton-item">

      </div>
      <div class="skeleton-item">

      </div>
      <div class="skeleton-item">

      </div>
      <div class="skeleton-item">

      </div>
      <div class="skeleton-item">

      </div>
      <div class="skeleton-item">

      </div>
    </div>
</div>
