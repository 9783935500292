
<div class="text-center">
    <div class="skeleton-container flex-wrap">
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <div class="skeleton-item mx-1 my-2"></div>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <div class="skeleton-item mx-1 my-2"></div>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <div class="skeleton-item mx-1 my-2"></div>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <div class="skeleton-item mx-1 my-2"></div>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <div class="skeleton-item mx-1 my-2"></div>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <div class="skeleton-item mx-1 my-2"></div>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <div class="skeleton-item mx-1 my-2"></div>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <div class="skeleton-item mx-1 my-2"></div>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <div class="skeleton-item mx-1 my-2"></div>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <div class="skeleton-item mx-1 my-2"></div>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <div class="skeleton-item mx-1 my-2"></div>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <div class="skeleton-item mx-1 my-2"></div>
      </div>
    </div>
</div>
